<template>
  <div>
    <img v-lazy="lazyLogo" class="logoWrapper">
    <h1>Token-Abfrage</h1>
    <p>
      Bitte gebe dein Zahlungstoken ein.
      <br>
      Rechnung {{invoiceYear}} / {{invoiceNumber}}
    </p>
    <div class="d-flex flex-row justify-content-center">
      <b-input v-model="token" @update="updateToken($event)" autofocus trim placeholder="XXXXXX" style="width: 10ch; text-align: center;"/>
    </div>
    <b-button @click="next" ref="next" variant="primary" :disabled="!valid" style="margin-top: 1rem;">Weiter</b-button>
  </div>
</template>

<script>
export default {
  name: 'Token',
  data() {
    return {
      invoiceYear: this.$route.params.invoiceYear,
      invoiceNumber: this.$route.params.invoiceNumber,
      token: '',
      lazyLogo: {
        src: 'logo.png',
        loading: 'logo-min.png'
      },
    };
  },
  mounted() {
    if (!this.$route.params.invoiceYear || !this.$route.params.invoiceNumber)
      this.$router.push('/');
  },
  methods: {
    next() {
      this.$router.push({
        name: 'Summary',
        params: {
          invoiceYear: this.invoiceYear,
          invoiceNumber: this.invoiceNumber,
          token: this.token,
        }
      });
    },
    updateToken(val) {
      if (val.length > 5) setTimeout(() => {
        this.$refs.next.focus();
      }, 100);
    },
  },
  computed: {
    valid() {
      const valid = this.invoiceYear 
        && this.invoiceNumber
        && this.invoiceYear.length === 4
        && this.invoiceNumber.length === 6
        && !isNaN(this.invoiceYear)
        && !isNaN(this.invoiceNumber)
        && this.token.length === 6
      return valid;
    }
  },
}
</script>

<style scoped>
</style>